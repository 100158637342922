import {useState} from "#app";

export function useStateFunctionBodyClass() {
    const bodyClasses = useState('body_classes', (): Array<String> => []);

    const setBodyClass = (bodyClass: Array<String>) => {
        bodyClasses.value = bodyClass;
    };

    const updateBodyClass = (bodyClass: string) => {
        if (bodyClasses.value.indexOf(bodyClass) === -1) {
            bodyClasses.value.push(bodyClass);
        }
    };

    const removeBodyClass = (bodyClass: string) => {
        if (bodyClasses.value.indexOf(bodyClass) !== -1) {
            bodyClasses.value.splice(bodyClasses.value.indexOf(bodyClass), 1);
        }
    };

    return {body_class: bodyClasses.value.join(' '), updateBodyClass, removeBodyClass}
}
